import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

class News extends Component {
    render(){
        return (
            <Row className="newsContainer" id="news">
                <h1>
                    NOVICE
                </h1>
                <div className="mainNews">
                <div className="newsArticle1">
                        <h3>Srečno in uspešno v 2025!</h3>
                            <p>
                            MDNS Maribor uspešno zaključuje leto 2024. V preteklem letu smo se uspešno soočili z mnogimi izzivi, pridobili nove člane med drugim pa sklenili novo partnerstvo s podjetjem AXIWI. S pomočjo njihovega komunikacijskega sistema smo še dodatno izboljšali sodelovanje med sodniki na igrišču. Vsem skupaj želimo srečno in uspešno novo leto 2025!
                            </p>
                            <a href="https://www.axiwi.com/ " target="_blank">
                            <img className="newsImage rounded" src='axiwiLogo.png' loading="lazy"></img>
                            <div class="overlay"></div>
                        </a>
                    </div>
                <div className="newsArticle1">
                        <h3>Dogodek rešen s pomočjo sistema AXIWI</h3>
                            <p>
                            Mladi sodniki Luka Pečarič, Adam Bezjak in Jani Ferčič so imeli priložnost prvič izkusiti uporabo komunikacijskega sistema na tekmi in jo uspešno izkoristili. Tekma se je odvijala proti koncu, ko se je med protinapadom gostujoče ekipe zgodil izven vidnega polja glavnega sodnika Adama. Igralec napadajoče ekipe je v teku spotaknil nasprotnega igralca, kateri se je poskušal vrniti v obrambo in preprečiti protinapad. Prišlo je do povračilnega ukrepa saj je igralec nad katerim je bil storjen prekršek, udaril nasprotnega igralca. Celotni dogodek je videl prvi pomočik Luka, kateri je to preko komunikacijskega sistema Axiwi nemudoma sporočil glavnemu sodniku. Akcija je bila pravilno prekinjena in kršitelji primerno disciplinsko kazovani.
                            </p>
                            {/* <a href="mladiaxiwi.jpg" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='mladiaxiwi.jpg' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a> */}
                    </div>
                    <div className="newsArticle1">
                        <h3>Mladi sodniki preizkusili komuikacijski sistem AXIWI</h3>
                            <p>
                            Mladi sodniki Luka, Adam in Jani (iz leve proti desni) prvič preizkusili komunikacijski sistem AXIWI.
                            </p>
                            <a href="mladiaxiwi.jpg" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='mladiaxiwi.jpg' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a>
                    </div>
                    <div className="newsArticle1">
                        <h3>Primer uporabe komunikacijskega sistema med tekmo</h3>
                            <p>
                            Jernej (slika, na sredini) in Elmedin (slika, levo) sta na tekmi 4. lige, s pomočjo komunikacijskega sistema AXIWI uspešno rešila kočljivo situacijo v kazenskem prostoru. Med prodorom napadalca po levem krilu, kar je nadzoroval Jernej kot glavni sodnik, je prišlo do prekrška med drugim napadalcem in branilcem ki sta se nahajala v kazenskem prostoru. 
                            Glavni sodnik tega ni mogel zaznati in pravilno ukrepati, zato mu je 1. pomočnik sodnika Elmedin pri tem pomagal in mu s pomočjo komunikacijskega sistema podal potrebno informacijo o storjenem prekršku napadalca nad branilcem. Glavni sodnik je nato pravilno dosodil prekršek za obrambo.
                            </p>
                            <a href="mnz.jpg" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='mnz.jpg' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a>
                    </div>
                    <div className="newsArticle1">
                    <h3>Priporočila za uporabo komunikacijskega sistema</h3>
                            <p>
                            Tomaž Jezeršek, sodnik 3. slovenske nogometne lige, s priporočili o uporabi komunikacijskega sistema AXIWI (klik na spodnjo sliko)
                            </p>
                            <a href="axiwi-clanek.pdf" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='komunikacijaNavodila.png' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a>
                    </div>
                    <div className="newsArticle2">
                    <h3>AXIWI na 2.SNL</h3>
                            <p>
                            Mariborski sodniki na tekmi 2. SNL z novim komunikacijskim sistemom AXIWI.
                            </p>
                            <a href="snl2.jpg" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='snl2.jpg' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a>
                    </div>
                    <div className="newsArticle3">
                        <h3>Komunikacijski sistemi AXIWI</h3>
                            <p>
                            MDNS Maribor je sklenilo sodelovanje z nizozemskim ponudnikom komunikacijskih sistemov za sodnike, AXIWI. Nove komunikacijski komunikacijski sistemi AXIWI AT-350 bodo sodnikom v pomoč pri deljenju pravice na slovenskih igriščih.
                            </p>
                        <a href="https://www.axiwi.com/ " target="_blank">
                            <img className="newsImage rounded" src='axiwiLogo.png' loading="lazy"></img>
                            <div class="overlay"></div>
                        </a>
                    </div>                    
                </div>
            </Row>
        )
    }
}

export default News;